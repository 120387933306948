/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Facebook(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/Facebook.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials.fbarm1}
        geometry={nodes.fbarm1.geometry}
        position={[-0.41, -0.71, -0.05]}
        rotation={[0, 0, 0.12]}
        scale={[0.44, 0.44, 0.44]}
      />
      <mesh
        material={materials.fbarm2}
        geometry={nodes.fbarm2.geometry}
        position={[0.4, -0.68, -0.06]}
        rotation={[0, 0, -0.2]}
        scale={[0.44, 0.44, 0.44]}
      />
      <mesh
        material={materials.ski}
        geometry={nodes.Head.geometry}
        position={[0.01, 0.18, -0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.44, 0.44, 0.44]}
      />
      <mesh
        material={materials.fbt}
        geometry={nodes.fbt.geometry}
        position={[0, -0.56, -0.08]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.44, 0.44, 0.44]}
      />
    </group>
  )
}

useGLTF.preload('/Facebook.glb')
