/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/Amazon.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials.teslaarm2}
        geometry={nodes.amzarm1.geometry}
        position={[0.39, -0.68, -0.1]}
        rotation={[0, 0, -0.2]}
        scale={[0.44, 0.44, 0.44]}
      />
      <mesh
        material={materials.teslaarm1}
        geometry={nodes.amzarm2.geometry}
        position={[-0.42, -0.7, -0.09]}
        rotation={[0, 0, 0.12]}
        scale={[0.44, 0.44, 0.44]}
      />
      <mesh
        material={materials.Sk}
        geometry={nodes.amzhead1.geometry}
        position={[-0.01, 0.14, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.44, 0.44, 0.44]}
      />
      <mesh
        material={materials.tamz1}
        geometry={nodes.tamz1.geometry}
        position={[-0.01, -0.57, -0.11]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.44, 0.44, 0.44]}
      />
    </group>
  )
}

useGLTF.preload('/Amazon.glb')
