/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useFrame } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'
import { useMatcapTexture } from "@react-three/drei";

export default function HeikoModel(props) {
  const group = useRef()
  const mesh = useRef()
  const { nodes, materials } = useGLTF('/Heiko.glb')
  const [matcapTexture] = useMatcapTexture(9, 1024)
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh ref={mesh}
        geometry={nodes.Grease_Monkey_3D_1.geometry}
        position={[-0.57, 0.33, 0.55]}
        scale={[1,1,1]}
      >
              <meshMatcapMaterial attach="material" matcap={matcapTexture} />
        </mesh>
    </group>
  )
}

useGLTF.preload('/Heiko.glb')
