/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Microsoft(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/Microsoft4.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials.arm}
        geometry={nodes.micrarm1.geometry}
        position={[-0.41, -0.66, -0.08]}
        rotation={[0, 0, 0.12]}
      />
      <mesh
        material={materials.arm2}
        geometry={nodes.microarm2.geometry}
        position={[0.4, -0.68, -0.09]}
        rotation={[0, 0, -0.2]}
      />
      <mesh
        material={materials.micro238}
        geometry={nodes.tmicro.geometry}
        position={[0, -0.57, -0.09]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        material={materials.sdfsdfs}
        geometry={nodes.microhead.geometry}
        position={[0.01, 0.17, 0.01]}
        rotation={[Math.PI / 2, 0, 0]}
      />
    </group>
  )
}

useGLTF.preload('/Microsoft4.glb')
