/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'
import { Canvas, useFrame, useUpdate, extend, useLoader, useThree } from 'react-three-fiber'
import { useAspect } from "@react-three/drei/useAspect";

export default function Ruxin(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/ruxin2.glb')
  const [video] = useState(() => {
    const vid = document.createElement("video");
    vid.src = 'Ruxin.mp4';
    vid.muted = true;
    vid.loop = true;
    vid.play();
    return vid;
  });
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials['Material.006']}
        geometry={nodes.Mercedes_OM502LA007.geometry}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.01, 0.01, 0.01]}
      >
        <meshBasicMaterial>
        <videoTexture flipX="true" attach="map" args={[video]} />
      </meshBasicMaterial>
      </mesh>
    </group>
  )
}

useGLTF.preload('/ruxin2.glb')
