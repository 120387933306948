/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Marthe4(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/Marthe4.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh material={materials.ALUMINIO} geometry={nodes.CONTROL.geometry} rotation={[1.15, 0, 0]}>
        <mesh material={materials.BACK} geometry={nodes.BACK.geometry} position={[0, 1.93, 0.02]} />
        <mesh
          material={materials.PLASTIC}
          geometry={nodes.BAND.geometry}
          position={[0, 2.4, 0.02]}
          scale={[1.59, 0.47, 1.89]}
        />
        <mesh material={materials.ALUMINIO} geometry={nodes.CIERRE.geometry} position={[0, -2.79, 0.18]} />
        <mesh material={materials.ALUMINIO} geometry={nodes.CORONA.geometry} position={[1.61, 2.45, -0.68]} />
        <mesh
          material={materials.GLASS}
          geometry={nodes.GLASS.geometry}
          position={[0, 2.4, 0.02]}
          scale={[1.59, 0.47, 1.89]}
        />
      </mesh>
    </group>
  )
}

useGLTF.preload('/Marthe4.glb')
