import React, { useRef, useState, useEffect } from 'react'
import axios from "axios"
import Login from './Login'
import { connect } from 'react-redux';

class Chat2 extends React.Component {
    divRef = React.createRef();
    end = React.createRef();
      
    resetreply = (e) => {
      this.props.dispatch({ type: "RESETREPLY" });
      e.preventDefault();
    }
    constructor(props) {
      super(props);
      var today = new Date(),
        date = today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear();
      this.handleChangeName = this.handleChangeName.bind(this);
      this.handleChangeMessage = this.handleChangeMessage.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.state = {
        error: null,
        isLoaded: false,
        items: [],
        CommentName: '',
        CommentMessage: '',
        Reply_to: '1',
        currentDate: date,
        channel: this.props.activeChannel
      };
    }
    handleChangeName(event) {
        this.setState({CommentName: localStorage.getItem('localStorageKey')});
    }
    handleChangeMessage(event) {
        this.setState({CommentMessage: event.target.value});
    }
  
    handleSubmit(event) {
      const reply_bool = this.props.reply_bool;
            fetch('https://the-engine.klassedenny.space/cockpit/api/collections/save/'+ this.props.activeChannel +'?token=07b7af13d226b4fc557d9b6b942596', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    data: {
                    Name: localStorage.getItem('nameofuser'),
                    Message: this.state.CommentMessage,
                    Date: this.state.currentDate,
                    Reply_bool: this.props.reply_bool,
                    Reply_to: this.props.reply_id
                    }
                })
            });
            this.props.dispatch({ type: "RESETREPLY" });
      event.preventDefault();
      this.setState(previousState => ({
        items: [...previousState.items, {Name: localStorage.getItem('nameofuser'), Message: this.state.CommentMessage, Date: this.state.currentDate, Reply_bool: this.props.reply_bool, Reply_to: this.props.reply_id}],
        CommentMessage: ''
      }));
      /*setTimeout(() => {
        this.divRef.current.scrollTo({top: 100000000, left: 0, behavior: 'smooth' });
      }, 100);*/
    }
    fetchPosts(c){
      fetch("https://the-engine.klassedenny.space/cockpit/api/collections/get/" + c + "?token=07b7af13d226b4fc557d9b6b942596")
        .then(res => res.json())
        .then(
          result => {
            this.setState({
              isLoaded: true,
              items: (result.entries),
            });
          },
          error => {
            this.setState({
              isLoaded: true,
              error: error
            });
          }
        )
        .then(
          /*ØsetTimeout(() => {
            this.divRef.current.scrollTo({top: 100000000, left: 0, behavior: 'smooth' });
          }, 100)*/
        );
    }
    componentDidMount() {
      this.fetchPosts(this.state.channel)
    }
    render() {
      const { error, isLoaded, items } = this.state;
      const reply_bool = this.props.reply_bool;
      const reply_id = this.props.reply_id;
      const reply_name = this.props.reply_name;
      let button;
      if (reply_bool) {
        button = <React.Fragment>replying</React.Fragment>;
      } else {
        button = <React.Fragment>Not replying</React.Fragment>;
      }
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {
        //console.log(this);
        return (
            <React.Fragment>
            <div class="chat" id="chat" ref={this.divRef}>
            <ul>
            {items.map(item => (
                <li class={item.Reply_bool ? 'message reply' : 'message'}>
                <div class="img"><img src="https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png"></img></div>
                <div class="meta">
                <div class="author">{item.Name} <date>{item.Date}</date></div>
                {item.Reply_bool == true &&
                <React.Fragment>
                  <div class="reply_img">
                    <img class="thumb" src={process.env.PUBLIC_URL + "Thumbs/" + item.Reply_to + ".png"} />
                  </div>
                </React.Fragment>
                } 
                <div class="body">{item.Message}</div>
                </div>
                </li>
            ))}
            </ul>
            <div class={reply_bool ? 'inputfield replying' : 'inputfield'}>
            <form onSubmit={this.handleSubmit} autocomplete="off">
              <div class="replyingto">Replying to {reply_name} <a href="#" onClick={this.resetreply}><div class="closebutton"></div></a></div>
            <input autocomplete="off" type="text" id="message" name="message" placeholder="Message #engineblock" value={this.state.CommentMessage} onChange={this.handleChangeMessage} />
            </form>
            </div>
            <div ref={this.end}></div>
            </div>
            </React.Fragment>
        );
      }
    }
  }


const mapStateToProps = state => {
    return {
    count: state.count,
    reply_id: state.chat.reply_id,
    reply_bool: state.chat.reply_bool,
    reply_name: state.chat.reply_name,
    activeChannel: state.channel.activeChannel
  };
}
export default connect(mapStateToProps)(Chat2);