/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Apple(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/Apple.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials.applearm1}
        geometry={nodes.applearm1.geometry}
        position={[0.4, -0.69, -0.06]}
        rotation={[0, 0, -0.2]}
        scale={[0.43, 0.43, 0.43]}
      />
      <mesh
        material={materials.appletarm2}
        geometry={nodes.appletarm2.geometry}
        position={[-0.4, -0.71, -0.05]}
        rotation={[0, 0, 0.12]}
        scale={[0.43, 0.43, 0.43]}
      />
      <mesh
        material={materials.applehead}
        geometry={nodes.applehead.geometry}
        position={[0.01, 0.19, -0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.43, 0.43, 0.43]}
      />
      <mesh
        material={materials.applet}
        geometry={nodes.applet.geometry}
        position={[0, -0.57, -0.08]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.43, 0.43, 0.43]}
      />
    </group>
  )
}

useGLTF.preload('/Apple.glb')
