/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function ChaoModel(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/ChaoModel.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0.03, -0.01, -0.11]} rotation={[Math.PI / 2, 0, 0]} scale={[0.21, 0.21, 0.21]}>
        <mesh material={materials.S} geometry={nodes.Mesh004.geometry} />
        <mesh material={materials.Face} geometry={nodes.Mesh004_1.geometry} />
        <mesh material={materials.HM} geometry={nodes.Mesh004_3.geometry} />
        <mesh material={materials.RING} geometry={nodes.Mesh004_4.geometry} />
      </group>
    </group>
  )
}

useGLTF.preload('/ChaoModel.glb')
