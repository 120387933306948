import React, { useRef, useState, useContext } from 'react'
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer'

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
  }
  componentDidMount(){
    setInterval(() => {
      window.location.reload(false);
    }, 1000 * 60 * 120);
  }
  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    if(!this.state.value.length == 0){
      this.props.dispatch({ type: "SETLOGIN", userName: this.state.value, userNameSet: true });
      event.preventDefault();
    }
    event.preventDefault();
  }
  handleOnAction (event) {
  }

  handleOnActive (event) {
  }

  handleOnIdle (event)  {
    if(this.props.userNameSet == true) {
      this.setState({value: ""})
      this.props.dispatch({ type: "SETLOGIN", userName: "", userNameSet: false });
    }
    
  }
render(){
  return (
    
    <React.Fragment>
          <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * 5}
          onActive={this.handleOnActive}
          onIdle={() => {this.handleOnIdle()}}
          onAction={this.handleOnAction}
          debounce={250}
        />
       <div class={this.props.userNameSet ? "loginpopup hide" : "loginpopup not"}> 
       Welcome! Please enter your name of choice:<br />
       <form onSubmit={this.handleSubmit}>
      <input type="text" value={this.state.value} onChange={this.handleChange} />
      <input value="JOIN" type="submit"></input>
      </form>
      </div>
      <div class={this.props.userNameSet ? "bgoverlay hide" : "bgoverlay not"}></div>
  </React.Fragment>
);
}};
function mapStateToProps(state) {
  return {
    activeChannel: state.channel.activeChannel,
    activeChannel_name: state.channel.activeChannel_name,
    userNameSet: state.login.userNameSet,
    userName: state.login.userName

  };
}
export default connect(mapStateToProps)(Login);
