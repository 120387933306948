/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function InsaPor(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/Insa2.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials['BestDressedSpectator-Insa-Vestimentaer-IMG_3163']}
        geometry={nodes['BestDressedSpectator-Insa-Vestimentaer-IMG_3163'].geometry}
        position={[0.52, 1, -0.09]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[5.13, 5.13, 5.13]}
      
      />
    </group>
  )
}

useGLTF.preload('/Insa2.glb')
