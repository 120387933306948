const loginReducer = (state={
userName: "",
userNameSet: false
},action)=>{

    switch (action.type){

    case 'SETLOGIN':
        return {
            userName: action.userName,
            userNameSet: action.userNameSet
        }
         default:
           return state
    }


}

export default loginReducer