/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from 'react-three-fiber'

export default function Christest({ ...props }) {
  const group = useRef()
  const mesh = useRef();
  const { nodes, materials } = useGLTF('/christest.glb')
  useFrame(() => {
    mesh.current.rotation.x += 0.01
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.Wiki.geometry}
        material={nodes.Wiki.material}
        rotation={[1.1, 0.23, -0.02]}
        scale={[1, 1, 1]}
        ref={mesh}
      />
    </group>
  )
}

useGLTF.preload('/christest.glb')
