import React, { useRef, useState, useMemo, useEffect, Suspense, createContext, useContext } from 'react'
import ReactDOM from 'react-dom'

import * as THREE from 'three'
import { CubeTextureLoader, updateProjectionMatrix } from "three";
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import {FBXLoader} from 'three/examples/jsm/loaders/FBXLoader'
import { Canvas, useFrame, useUpdate, extend, useLoader, useThree } from 'react-three-fiber'
import { useAspect } from "@react-three/drei/useAspect";
import { FlyControls, OrbitControls, Stars, PerspectiveCamera, Environment } from "@react-three/drei";
import CurveModifier from "@react-three/drei/CurveModifier.js"
import { useSpring, animated, config } from "react-spring";
import ThreeMeshUI from 'three-mesh-ui'
import M3 from "./Mercedesneu10.js"
import EngineModel from "./EngineModel.js"
import { connect } from 'react-redux';
import Soundscape from './Soundscape.mp3'
import Conradimg from './Works/Conrad-small.png'
import InsaText from './Works/InsaText.png'
import Taissaimg from './Works/Taissa.jpg'
import Leylaimg from './Works/Leyla.png'
import Amadeusi from './Works/Amadeus.png'
import Aurel1i from './Works/Aurel1.png'
import Aurel2i from './Works/Aurel2.png'
import Gustavi from './Works/Gustav.png'
import Kimi from './Works/Kim.png'
import Laurai from './Works/Laura.png'
import Mathiasi from './Works/Mathias.png'
import Merlini from './Works/Merlin.png'
import Sheni from './Works/Shen.png'
import Talyai from './Works/Talya.png'
import YiI from './Works/Yi.png'
import Christest from "./Christest";
import Ipad from "./Ipad1"
import Ruxin from "./Ruxin2.js"
import Raheltest from "./Raheltest.js"
import Paul from "./PaulFrames.js"
import CloverModel from "./Clover.js"
import Lena from "./Lena.js"
import MacBook from "./MacBook.js"
import InsaPor from "./Insa2.js"
import HeikoModel from "./Heiko.js"
import Leon2 from "./Leon2.js"
import Karla from "./Karla.js"
import Sam from "./Sam.js"
import Marthe4 from "./Marthe4.js"
import ChaoModel from "./ChaoModel"
import Microsoft from "./Microsoft4"
import Tesla from "./Tesla"
import Facebook from "./Facebook"
import Apple from "./Apple"
import Amazon from "./Amazon"
import Ali from "./Ali"
extend({ OrbitControls });
const ControlsContext = createContext()

const ControlsContainer = ({ children }) => {
  const controls = useRef()
  console.log(controls);
  return (
    <ControlsContext.Provider value={{ controls: controls.current }}>
      <OrbitControls ref={controls}/>
      {children}
    </ControlsContext.Provider>
  )
}
function Camera(props) {
  const ref = useRef()
  const { setDefaultCamera } = useThree()
  // Make the camera known to the system
  useEffect(() => void setDefaultCamera(ref.current), [])
  // Update it every frame
  useFrame(() => ref.current.updateMatrixWorld())
  return <perspectiveCamera ref={ref} {...props} />
}
function SkyBox() {
  const { scene } = useThree();
  const loader = new CubeTextureLoader();
  // The CubeTextureLoader load method takes an array of urls representing all 6 sides of the cube.
  const texture = loader.load([
    "/CubeMap/nx.png",
    "/CubeMap/ny.png",
    "/CubeMap/nz.png",
    "/CubeMap/px.png",
    "/CubeMap/py.png",
    "/CubeMap/pz.png",
  ]);
  // Set the scene background property to the resulting texture.
  scene.environment = texture;
  return null;
}
function Conrad() {
  const texture = useLoader(THREE.TextureLoader, Conradimg)
  return (
    <mesh rotation={[THREE.Math.degToRad(-90), 0, THREE.Math.degToRad(-90)]}>
      <planeBufferGeometry attach="geometry" args={[8,3.5]} />
      <meshBasicMaterial depthWrite={false} transparent attach="material" map={texture} toneMapped={false} side={THREE.DoubleSide} />
    </mesh>
  )
}
function Taissa() {
  const texture = useLoader(THREE.TextureLoader, Taissaimg)
  return (
    <mesh rotation={[THREE.Math.degToRad(0), THREE.Math.degToRad(180), THREE.Math.degToRad(0)]}>
      <planeBufferGeometry attach="geometry" args={[8,4]} />
      <meshBasicMaterial  attach="material" map={texture} toneMapped={false} side={THREE.DoubleSide} />
    </mesh>
  )
}

function Leyla() {
  const texture = useLoader(THREE.TextureLoader, Leylaimg)
  const mesh = useRef()
  useFrame(() => {
    mesh.current.rotation.z += 0.02
  })
  return (
    <mesh ref={mesh} rotation={[THREE.Math.degToRad(0), THREE.Math.degToRad(180), THREE.Math.degToRad(0)]}>
      <planeBufferGeometry attach="geometry" args={[4,4]} />
      <meshBasicMaterial transparent attach="material" map={texture} toneMapped={false} side={THREE.DoubleSide} />
    </mesh>
  )
}
function Heiko() {
  const texture = useLoader(THREE.TextureLoader, Leylaimg)
  const mesh = useRef()
  useFrame(() => {
    mesh.current.rotation.y += 0.02
  })
  return (
    <mesh ref={mesh} rotation={[THREE.Math.degToRad(90), THREE.Math.degToRad(0), THREE.Math.degToRad(0)]}>
      <HeikoModel />
    </mesh>
  )
}
function Yi() {
  const [x, y] = useAspect("cover", (1800 / 2), (800 / 2));
  const texture = useLoader(THREE.TextureLoader, YiI)

  return (
    <mesh scale={[1, 1, 1]} rotation={[0, THREE.Math.degToRad(-90), 0]}>
      <planeBufferGeometry args={[2, 0.8]} />
      <meshBasicMaterial transparent attach="material" map={texture} toneMapped={false} side={THREE.DoubleSide} />
    </mesh>
  )
}
function Merlin() {
  const [x, y] = useAspect("cover", (1800 / 2), (800 / 2));
  const texture = useLoader(THREE.TextureLoader, Merlini)
  return (
    <mesh scale={[1, 1, 1]} rotation={[0, THREE.Math.degToRad(-90), 0]}>
      <planeBufferGeometry args={[2.4, 0.8]} />
      <meshBasicMaterial transparent attach="material" map={texture} toneMapped={false} side={THREE.DoubleSide} />
    </mesh>
  )
}
function Kim() {
  const [x, y] = useAspect("cover", 700, 400);
  const texture = useLoader(THREE.TextureLoader, Kimi)

  return (
    <mesh scale={[1, 1, 1]} rotation={[0, THREE.Math.degToRad(-90), 0]}>
      <planeBufferGeometry args={[1.7, 1]} />
      <meshBasicMaterial transparent attach="material" map={texture} toneMapped={false} side={THREE.DoubleSide} />
    </mesh>
  )
}
function Gustav() {
  const [x, y] = useAspect("cover", 700, 400);
  const texture = useLoader(THREE.TextureLoader, Gustavi)
  return (
    <mesh scale={[1, 1, 0.1]} rotation={[0, THREE.Math.degToRad(-90), 0]}>
      <boxBufferGeometry args={[1.7, 35, 0.1]} />
      <meshBasicMaterial transparent attach="material" map={texture} toneMapped={false} side={THREE.DoubleSide} />

    </mesh>
  )
}
function Julia() {
  const [x, y] = useAspect("cover", 1000, 1000);
  const [video] = useState(() => {
    const vid = document.createElement("video");
    vid.src = 'Julia4.webm';
    vid.muted = true;
    vid.loop = true;
    vid.play();
    return vid;
  });
  return (
    <mesh scale={[1, 1, 1]} rotation={[0, THREE.Math.degToRad(90), 0]}>
      <planeBufferGeometry args={[1, 1]} />
      <meshBasicMaterial depthWrite={false} side={THREE.DoubleSide} toneMapped={false} transparent>
        <videoTexture attach="map" args={[video]} format={THREE.RGBAFormat} />
      </meshBasicMaterial>
    </mesh>
  )
}
function Talya() {
  const [x, y] = useAspect("cover", (1080 / 2), (640 / 2));
  const texture = useLoader(THREE.TextureLoader, Talyai)

  return (
    <mesh scale={[1, 1, 1]} rotation={[0, THREE.Math.degToRad(180), 0]}>
      <planeBufferGeometry args={[2, 1]} />
      <meshBasicMaterial transparent attach="material" map={texture} toneMapped={false} side={THREE.DoubleSide} />

    </mesh>
  )
}
function Mathias() {
  const [x, y] = useAspect("cover", (1080 / 2), (640 / 2));
  const texture = useLoader(THREE.TextureLoader, Mathiasi)
  return (
    <mesh scale={[1, 1, 1]} rotation={[0, THREE.Math.degToRad(180), 0]}>
      <planeBufferGeometry args={[2, 1]} />
      <meshBasicMaterial transparent attach="material" map={texture} toneMapped={false} side={THREE.DoubleSide} />

    </mesh>
  )
}
function Amadeus() {
  const [x, y] = useAspect("cover", (1080 / 2), (640 / 2));
  const texture = useLoader(THREE.TextureLoader, Amadeusi)

  return (
    <mesh scale={[1, 1, 1]} rotation={[0, THREE.Math.degToRad(180), 0]}>
      <planeBufferGeometry args={[2, 1]} />
      <meshBasicMaterial transparent attach="material" map={texture} toneMapped={false} side={THREE.DoubleSide} />

    </mesh>
  )
}
function Laura() {
  const [x, y] = useAspect("cover", (1080 / 2), (640 / 2));
  const texture = useLoader(THREE.TextureLoader, Laurai)

  return (
    <mesh scale={[1, 1, 1]} rotation={[0, THREE.Math.degToRad(180), 0]}>
      <planeBufferGeometry args={[2, 1.2]} />
      <meshBasicMaterial transparent attach="material" map={texture} toneMapped={false} side={THREE.DoubleSide} />

    </mesh>
  )
}
function ChaoVid() {
  const [x, y] = useAspect("cover", (1080 / 2), (640 / 2));
  const [video] = useState(() => {
    const vid = document.createElement("video");
    vid.src = 'ChaoVid.webm';
    vid.muted = true;
    vid.loop = true;
    vid.play();
    return vid;
  });
  return (
    <mesh scale={[1, 1, 1]} rotation={[0, THREE.Math.degToRad(180), 0]}>
      <planeBufferGeometry args={[2, 1.2]} />
      <meshBasicMaterial format={THREE.RGBAFormat} transparent>
        <videoTexture attach="map" args={[video]} format={THREE.RGBAFormat} />
      </meshBasicMaterial>
    </mesh>
  )
}
function Aurel1() {
  const [x, y] = useAspect("cover", 700, 400);
  return (
    <mesh scale={[1, 1, 1]} rotation={[0, THREE.Math.degToRad(180), 0]}>
      <planeBufferGeometry args={[2, 1.2]} />
      <meshBasicMaterial>
      </meshBasicMaterial>
    </mesh>
  )
}
function Aurel2() {
  const [x, y] = useAspect("cover", 700, 400);

  return (
    <mesh scale={[1, 1, 1]} rotation={[0, THREE.Math.degToRad(180), 0]}>
      <planeBufferGeometry args={[2, 1.2]} />
      <meshBasicMaterial>
      </meshBasicMaterial>
    </mesh>
  )
}
function Shen() {
  const [x, y] = useAspect("cover", 1500, 800);

  return (
    <mesh scale={[1, 1, 1]} rotation={[0, THREE.Math.degToRad(180), 0]}>
      <planeBufferGeometry args={[3, 1.5]} />
      <meshBasicMaterial>
      </meshBasicMaterial>
    </mesh>
  )
}
function Saskia() {
  const [x, y] = useAspect("cover", 700, 1300);
  const [video] = useState(() => {
    const vid = document.createElement("video");
    vid.src = 'Saskia.webm';
    vid.muted = true;
    vid.loop = true;
    vid.play();
    return vid;
  });
  const mesh = useRef()
  useFrame(() => {
    mesh.current.rotation.x += Math.sin(Date.now() * 0.001) * (-Math.PI / 15) * 0.01;
  })
  return (
    <mesh ref={mesh} scale={[-1, 1, 1]} rotation={[0, THREE.Math.degToRad(180), 0]}>
      <planeBufferGeometry args={[3, 3.5]} />
      <meshBasicMaterial side={THREE.DoubleSide}>
        <videoTexture attach="map" args={[video]} />
      </meshBasicMaterial>
    </mesh>
  )
}
function InsaTextbox() {
  const texture = useLoader(THREE.TextureLoader, InsaText)
  return (
    <mesh rotation={[THREE.Math.degToRad(0), THREE.Math.degToRad(180), THREE.Math.degToRad(0)]}>
      <planeBufferGeometry attach="geometry" args={[10,17]} />
      <meshBasicMaterial transparent  attach="material" map={texture} toneMapped={false} side={THREE.DoubleSide} />
    </mesh>
  )
}
function Clover() {
  const points = [
        [-2.2198753356933594, 0.28600847721099854, 0.8020967245101929] ,
    [-2.029668092727661, 2.051121234893799, 2.480395793914795] ,
    [-1.4779620170593262, 2.163902521133423, 2.1437487602233887] ,
    [-0.3905600309371948, 1.9269860982894897, 1.3859238624572754] ,
    [-0.5579230785369873, 2.2045211791992188, 2.655792474746704] ,
    [-3.620406150817871, 0.4799492359161377, 2.6558945178985596] ,
    [-3.952644109725952, 2.262249231338501, 1.1065800189971924] ,
    [-3.7621326446533203, 2.392957925796509, 2.0967681407928467] ,
    [-2.1667206287384033, -0.47851359844207764, -0.7746061682701111] ,
    [-1.0601402521133423, -0.11929768323898315, 2.630256175994873] ,
    [-3.06083083152771, 0.23993374407291412, 3.3308868408203125] ,
    [-5.0478715896606445, -0.9392133951187134, 0.009180545806884766] ,
    [-2.3526484966278076, 0.03689694404602051, 1.0370606184005737] ,
    [-2.2335381507873535, 0.7434420585632324, 1.6587868928909302] ,
    [-1.482753038406372, 1.014735460281372, 1.0370606184005737] ,
    [-1.2256665229797363, 0.19890320301055908, -0.4412004053592682] ,
    [-1.991760492324829, 1.1437525749206543, -0.4412004053592682] ,
    [-4.747169017791748, 0.1024022102355957, 1.7885456085205078] ,
    [-2.664569854736328, 1.642005205154419, 0.762667179107666] ,
    [-4.213012218475342, 0.5340485572814941, 1.8086183071136475] ,
    [-3.846989393234253, 0.2616593837738037, 2.5309901237487793] ,
    [-4.362399578094482, 0.009724617004394531, 1.8086183071136475] ,
    [-3.3448164463043213, 2.147010087966919, 2.240551233291626] ,
    [-2.6330556869506836, 1.734574556350708, 0.762667179107666] ,
    [-2.8786888122558594, 0.17453250288963318, 1.7261157035827637] ,
    [-2.053234577178955, 0.6414101719856262, 1.0370606184005737] ,
    [-1.9186902046203613, 1.6712039709091187, 1.6587868928909302] ,
    [-2.6654038429260254, 1.894148349761963, 1.0370606184005737] ,
    [-3.1185078620910645, 1.045453667640686, 1.7261157035827637] ,
    [-3.0437352657318115, 0.11095055937767029, 1.7261157035827637] ,
    [-1.867992639541626, 0.38715213537216187, 1.0370606184005737] ,
    [-1.8433582782745361, 2.0304555892944336, 1.6587868928909302] ,
  ];
  const scale = 5;
//========== Convert the array of points into vertices (in Blender the z axis is UP so we swap the z and y)
for (var i = 0; i < points.length; i++) {
  var x = points[i][0] * scale;
  var y = points[i][1] * scale;
  var z = points[i][2] * scale;
  points[i] = new THREE.Vector3(x, z, -y);
}
const curve =  new THREE.CatmullRomCurve3(points);
const radius = .25;
const curvePathSegs = curve.getPoints(20);
//========== Create a tube geometry that represents our curve
const geometry = new THREE.BufferGeometry().setFromPoints(points);
const curveRef = useRef()
const geomRef = useRef()
const mesh = useRef()

  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)

  // Rotate mesh every frame, this is outside of React without overhead
  let number = 0;
  useFrame(() => {
    const moveTime = Date.now() * 0.0001;
    var pos = curve.getPointAt(moveTime % 1);
    mesh.current.position.x = pos.x;
    mesh.current.position.y = pos.y;
    mesh.current.position.z = pos.z;
  })

return (
  <mesh visible position={[20, -10, 5]} rotation={[0, 0, 0]} scale={[0.9,0.9,0.9]}>
    <mesh ref={mesh} scale={[0.2, 0.2, 0.2]}><CloverModel /></mesh>
  </mesh>
)
}
function Jakob() {
  const points = [
    [1.096606969833374, 0, 0] ,
    [-3.1598055362701416, -4.739071369171143, 0.0] ,
    [-5.198199272155762, -3.8462026119232178, 0.0] ,
    [-5.70473051071167, -1.820077896118164, 0] ,
    [-3.3781211376190186, 1.3736441135406494, 0.0] ,
    [1.0690512657165527, 2.172074317932129, 0] ,
    [3.842094659805298, -1.7428109645843506, 0.0] ,
    [0.3993997573852539, -0.4120934009552002, 0.0] ,
    [-1.5494575500488281, -3.382598400115967, 0.0] ,
    [-5.112346172332764, -4.4643425941467285, 0.0] ,
    [-6.580428123474121, -1.3908144235610962, 0.0] ,
    [-1.0, 0.0, 0] ,
    [1.0, 0.0, 0.0],
  ];
  const scale = 5;
//========== Convert the array of points into vertices (in Blender the z axis is UP so we swap the z and y)
for (var i = 0; i < points.length; i++) {
  var x = points[i][0] * scale;
  var y = points[i][1] * scale;
  var z = points[i][2] * scale;
  points[i] = new THREE.Vector3(x, z, -y);
}
const curve =  new THREE.CatmullRomCurve3(points, true);
//========== Create a tube geometry that represents our curve
const geometry = new THREE.BufferGeometry().setFromPoints(points);
const curveRef = useRef()
const geomRef = useRef()
const mesh = useRef()

  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)

  // Rotate mesh every frame, this is outside of React without overhead
  let number = 0;
  const axis = new THREE.Vector3();
  const up = new THREE.Vector3(0, 0, 1);
  useFrame(() => {
    const moveTime = Date.now() * 0.00002;
    var pos = curve.getPointAt(moveTime % 1);
    var tangent = curve.getTangentAt(moveTime % 1).normalize();
    axis.crossVectors(up, tangent).normalize();
    var radians = Math.acos(up.dot(tangent));
    mesh.current.quaternion.setFromAxisAngle(axis, radians);
    mesh.current.position.x = pos.x;
    mesh.current.position.y = pos.y;
    mesh.current.position.z = pos.z;
  })

return (
  <mesh visible position={[0, -30, 0]} rotation={[0, 30, 0]}>
    <mesh ref={mesh} scale={[2, 2, 2]}><Tesla /></mesh>
  </mesh>
)
}
function Jakob2() {
  const points = [
    [2.0647847652435303, -0.43506360054016113, 0.0] ,
    [2.302084445953369, -4.098378658294678, 0.0] ,
    [4.452613353729248, -0.07911416888237, 0.0] ,
    [-0.3082137107849121, 2.3235456943511963, 0.0] ,
    [-4.312646865844727, 1.4188404083251953, 0.0] ,
    [0.8782846927642822, -1.6215627193450928, 0.0] ,
    [-0.9014632701873779, -3.2085046768188477, 0.0] ,
    [-4.297815799713135, -5.5963335037231445, 0.0] ,
    [-4.054872512817383, -1.423293113708496, 0.0] ,
    [-1.0, 0.0, 0.0] ,
    [1.0, 0.0, 0.0] ,
  ];
  const scale = 5;
//========== Convert the array of points into vertices (in Blender the z axis is UP so we swap the z and y)
for (var i = 0; i < points.length; i++) {
  var x = points[i][0] * scale;
  var y = points[i][1] * scale;
  var z = points[i][2] * scale;
  points[i] = new THREE.Vector3(x, z, -y);
}
const curve =  new THREE.CatmullRomCurve3(points, true);
//========== Create a tube geometry that represents our curve
const geometry = new THREE.BufferGeometry().setFromPoints(points);
const curveRef = useRef()
const geomRef = useRef()
const mesh = useRef()

  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)

  // Rotate mesh every frame, this is outside of React without overhead
  let number = 0;
  const axis = new THREE.Vector3();
  const up = new THREE.Vector3(0, 0, 1);
  useFrame(() => {
    const moveTime = Date.now() * 0.00002;
    var pos = curve.getPointAt(moveTime % 1);
    var tangent = curve.getTangentAt(moveTime % 1).normalize();
    axis.crossVectors(up, tangent).normalize();
    var radians = Math.acos(up.dot(tangent));
    mesh.current.quaternion.setFromAxisAngle(axis, radians);
    mesh.current.position.x = pos.x;
    mesh.current.position.y = pos.y;
    mesh.current.position.z = pos.z;
  })

return (
  <mesh visible position={[0, -30, 0]} rotation={[0, THREE.Math.degToRad(180), 0]}>
    <mesh ref={mesh} scale={[2, 2, 2]}><Microsoft /></mesh>
  </mesh>
)
}
function Jakob3() {
  const points = [
    [1.1776010990142822, -0.02779972553253174, 0.0] ,
    [1.298064112663269, -1.1953644752502441, 0.0] ,
    [-0.7590740919113159, -2.2054007053375244, 0.0] ,
    [-3.2331998348236084, -4.948251247406006, 0.0] ,
    [-4.882616996765137, -3.2988336086273193, 0.0] ,
    [-4.7343549728393555, -1.5011545419692993, 0.0] ,
    [-2.491888999938965, -2.4463260173797607, 0.0] ,
    [-2.556753635406494, 0.10193037986755371, 0.0] ,
    [1.0200722217559814, -2.2702646255493164, 0.0] ,
    [-0.5274147391319275, -3.3636982440948486, 0.0] ,
    [-3.863314390182495, -3.3266327381134033, 0.0] ,
    [-3.5760560035705566, -1.4177571535110474, 0.0] ,
    [-1.0, 0.0, 0.0] ,
    [1.0, 0.0, 0.0] ,    
  ];
  const scale = 5;
//========== Convert the array of points into vertices (in Blender the z axis is UP so we swap the z and y)
for (var i = 0; i < points.length; i++) {
  var x = points[i][0] * scale;
  var y = points[i][1] * scale;
  var z = points[i][2] * scale;
  points[i] = new THREE.Vector3(x, z, -y);
}
const curve =  new THREE.CatmullRomCurve3(points, true);
//========== Create a tube geometry that represents our curve
const geometry = new THREE.BufferGeometry().setFromPoints(points);
const curveRef = useRef()
const geomRef = useRef()
const mesh = useRef()

  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)

  // Rotate mesh every frame, this is outside of React without overhead
  let number = 0;
  const axis = new THREE.Vector3();
  const up = new THREE.Vector3(0, 0, 1);
  useFrame(() => {
    const moveTime = Date.now() * 0.00002;
    var pos = curve.getPointAt(moveTime % 1);
    var tangent = curve.getTangentAt(moveTime % 1).normalize();
    axis.crossVectors(up, tangent).normalize();
    var radians = Math.acos(up.dot(tangent));
    mesh.current.quaternion.setFromAxisAngle(axis, radians);
    mesh.current.position.x = pos.x;
    mesh.current.position.y = pos.y;
    mesh.current.position.z = pos.z;
  })

return (
  <mesh visible position={[0, -30, 0]} rotation={[0, THREE.Math.degToRad(50), 0]}>
    <mesh ref={mesh} scale={[2, 2, 2]}><Facebook /></mesh>
  </mesh>
)
}
function Jakob4() {
  const points = [
    [2.0647847652435303, -0.43506360054016113, 0.0] ,
    [2.302084445953369, -4.098378658294678, 0.0] ,
    [4.452613353729248, -0.07911416888237, 0.0] ,
    [-0.3082137107849121, 2.3235456943511963, 0.0] ,
    [-4.312646865844727, 1.4188404083251953, 0.0] ,
    [0.8782846927642822, -1.6215627193450928, 0.0] ,
    [-0.9014632701873779, -3.2085046768188477, 0.0] ,
    [-4.297815799713135, -5.5963335037231445, 0.0] ,
    [-4.054872512817383, -1.423293113708496, 0.0] ,
    [-1.0, 0.0, 0.0] ,
    [1.0, 0.0, 0.0] ,
  ];
  const scale = 5;
//========== Convert the array of points into vertices (in Blender the z axis is UP so we swap the z and y)
for (var i = 0; i < points.length; i++) {
  var x = points[i][0] * scale;
  var y = points[i][1] * scale;
  var z = points[i][2] * scale;
  points[i] = new THREE.Vector3(x, z, -y);
}
const curve =  new THREE.CatmullRomCurve3(points, true);
//========== Create a tube geometry that represents our curve
const geometry = new THREE.BufferGeometry().setFromPoints(points);
const curveRef = useRef()
const geomRef = useRef()
const mesh = useRef()

  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)

  // Rotate mesh every frame, this is outside of React without overhead
  let number = 0;
  const axis = new THREE.Vector3();
  const up = new THREE.Vector3(0, 0, 1);
  useFrame(() => {
    const moveTime = Date.now() * 0.00002;
    var pos = curve.getPointAt(moveTime % 1);
    var tangent = curve.getTangentAt(moveTime % 1).normalize();
    axis.crossVectors(up, tangent).normalize();
    var radians = Math.acos(up.dot(tangent));
    mesh.current.quaternion.setFromAxisAngle(axis, radians);
    mesh.current.position.x = pos.x;
    mesh.current.position.y = pos.y;
    mesh.current.position.z = pos.z;
  })

return (
  <mesh visible position={[0, -30, 0]} rotation={[0, THREE.Math.degToRad(10), 0]}>
    <mesh ref={mesh} scale={[2, 2, 2]}><Amazon /></mesh>
  </mesh>
)
}
function Jakob5() {
  const points = [
    [2.0647847652435303, -0.43506360054016113, 0.0] ,
    [2.302084445953369, -4.098378658294678, 0.0] ,
    [4.452613353729248, -0.07911416888237, 0.0] ,
    [-0.3082137107849121, 2.3235456943511963, 0.0] ,
    [-4.312646865844727, 1.4188404083251953, 0.0] ,
    [0.8782846927642822, -1.6215627193450928, 0.0] ,
    [-0.9014632701873779, -3.2085046768188477, 0.0] ,
    [-4.297815799713135, -5.5963335037231445, 0.0] ,
    [-4.054872512817383, -1.423293113708496, 0.0] ,
    [-1.0, 0.0, 0.0] ,
    [1.0, 0.0, 0.0] ,
  ];
  const scale = 5;
//========== Convert the array of points into vertices (in Blender the z axis is UP so we swap the z and y)
for (var i = 0; i < points.length; i++) {
  var x = points[i][0] * scale;
  var y = points[i][1] * scale;
  var z = points[i][2] * scale;
  points[i] = new THREE.Vector3(x, z, -y);
}
const curve =  new THREE.CatmullRomCurve3(points, true);
//========== Create a tube geometry that represents our curve
const geometry = new THREE.BufferGeometry().setFromPoints(points);
const curveRef = useRef()
const geomRef = useRef()
const mesh = useRef()

  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)

  // Rotate mesh every frame, this is outside of React without overhead
  let number = 0;
  const axis = new THREE.Vector3();
  const up = new THREE.Vector3(0, 0, 1);
  useFrame(() => {
    const moveTime = Date.now() * 0.00002;
    var pos = curve.getPointAt(moveTime % 1);
    var tangent = curve.getTangentAt(moveTime % 1).normalize();
    axis.crossVectors(up, tangent).normalize();
    var radians = Math.acos(up.dot(tangent));
    mesh.current.quaternion.setFromAxisAngle(axis, radians);
    mesh.current.position.x = pos.x;
    mesh.current.position.y = pos.y;
    mesh.current.position.z = pos.z;
  })

return (
  <mesh visible position={[0, -30, 0]} rotation={[0, THREE.Math.degToRad(100), 0]}>
    <mesh ref={mesh} scale={[2, 2, 2]}><Ali /></mesh>
  </mesh>
)
}
function Chao() {
const curve = new THREE.EllipseCurve(
	0,  0,            // ax, aY
	10, 15,           // xRadius, yRadius
	0,  2 * Math.PI,  // aStartAngle, aEndAngle
	false,            // aClockwise
	0                 // aRotation
);
const curvePathSegs = curve.getPoints(20);
const curve2 =  new THREE.CatmullRomCurve3(curvePathSegs);
//========== Create a tube geometry that represents our curve
const geometry = new THREE.BufferGeometry().setFromPoints(curvePathSegs);
const curveRef = useRef()
const geomRef = useRef()
const mesh = useRef()
  useFrame(() => {
    /*const moveTime = Date.now() * 0.0001;
    var pos = curve.getPointAt(moveTime % 1);
    mesh.current.position.x = pos.x;
    mesh.current.position.y = pos.y;
    mesh.current.position.z = pos.z;*/
  })

return (
  <mesh visible position={[0, -10, 0]} rotation={[0, 0, 0]} scale={[1,1,1]}>
        <mesh ref={mesh} scale={[1, 1, 1]}><ChaoModel /></mesh>
  </mesh>
)
}
function Light({ brightness, color }) {
  return (
    <rectAreaLight
      width={100}
      height={100}
      color={color}
      intensity={brightness}
      position={[0, 0, 5]}
      lookAt={[0, 0, 0]}
      penumbra={1}
      castShadow
    />
  );
}
function Button() {
  const [active, setActive] = useState(false)
  const [zoom, set] = useState(true)
  useEffect(() => void (document.body.style.cursor = active ? 'pointer' : 'auto'), [active])

  const light = useRef()
  const dummy = new THREE.Vector3()
  useFrame((state, delta) => {
    const step = 0.1
    state.camera.fov = THREE.MathUtils.lerp(state.camera.fov, zoom ? 10 : 42, step)
    state.camera.position.lerp(dummy.set(zoom ? 25 : 10, zoom ? 1 : 5, zoom ? 0 : 10), step)
    state.camera.lookAt(0, 0, 0)
    state.camera.updateProjectionMatrix()
  })

  return (
    <>
      <mesh receiveShadow castShadow onClick={() => set(!zoom)} onPointerOver={() => setActive(true)} onPointerOut={() => setActive(false)}>
        <sphereBufferGeometry args={[0.75, 64, 64]} />
        <meshPhysicalMaterial color={active ? 'purple' : '#e7b056'} clearcoat={1} clearcoatRoughness={0} />
      </mesh>
    </>
  )
}
//CAMERA MOVEMENT//
function Dolly() {
  // This one makes the camera move in and out
  useFrame(({ clock, camera }) => {
    camera.position.z = 50 + Math.sin(clock.getElapsedTime()) * 30
  })
  return null
}
function UpdateCamera({ x, y, z }) {
  const { camera, OrbitControls } = useThree();
  const controls = useContext(ControlsContext);
  const dummy = new THREE.Vector3()
  console.log(controls);
  useEffect(() =>{
    /*camera.position.x = 141.5;
    camera.position.y = -123;
    camera.position.z = 9;
    camera.zoom = 1;
    camera.rotation.x = 90;
    camera.rotation.y = 90;
    camera.rotation.z = 90;
    camera.lookAt([41.5, -123, 9]);
    camera.updateProjectionMatrix()*/
  })
  return null
}
class Scene extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      emoji_items: []
    };
    this.mounted = true;
  }
  
  componentWillUnmount() {
    this.mounted = false;

}
  componentDidMount() {
    this.mounted = true;
    if(this.mounted) {

    fetch("https://the-engine.klassedenny.space/cockpit/api/collections/get/Reactions_Work?token=07b7af13d226b4fc557d9b6b942596")
    .then(res => res.json())
    .then(
      result => {
        this.setState({
          isLoaded: true,
          emoji_items: (result.entries),
        });
        console.log(result.entries)
      },
      error => {
        this.setState({
          isLoaded: true,
          error: error
        });
      }
    )
    .then(
    );
    }
  }
  reply(name, title, desc, id, vid, channel) {
    this.props.dispatch({ type: "REPLY", bool: true, id: id, name: name, workartist: name, worktitle: title, workdesc: desc, workvid: vid });
    this.props.dispatch({ type: "SWITCHCHANNEL", channel: channel });
    return false;
  }
  updateEmoji(emoji) {
    const currentId = this.props.reply_id;
    const emojiname = emoji;
    const elementsIndex = this.state.emoji_items.findIndex(element => element.id == currentId )
    let newArray = [...this.state.emoji_items]
    newArray[elementsIndex] = {...newArray[elementsIndex], [emojiname]: +newArray[elementsIndex].[emojiname] + 1}
    const cockpit_id = newArray[elementsIndex]._id;
    const NewHeartCount = newArray[elementsIndex].[emojiname];
    this.setState({
      emoji_items: newArray,
    });
      fetch('https://the-engine.klassedenny.space/cockpit/api/collections/save/Reactions_Work?token=07b7af13d226b4fc557d9b6b942596', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            data: {
              id: currentId,
              _id: cockpit_id, 
              [emojiname]: NewHeartCount
            }
        })
      })
      .then(res=>res.json())
      .then(entry => console.log(entry));
    }  
    intersectionsFilter(intersections) {
      return intersections?.length ? [intersections[0]] : intersections
    }
render() {
  const replybool = this.props.reply_bool;
  const VidBool = this.props.work_video;
  const currentId = this.props.reply_id;
  return (
    <div class="scene">
      <audio autoplay={true} loop={true}><source src={Soundscape} /></audio>
      <div class={replybool ? 'workdetails' : 'workdetails hidden'}>
  <div class="title">{this.props.work_title}</div>
  <div class="artist">{this.props.work_artist}</div>
  <div class="desc">
  <div dangerouslySetInnerHTML={{__html: this.props.work_desc}} />
  </div>
  <div class="emojis">
  <a href="#" onClick={() => this.updateEmoji("lol")}><div class="emoji lol"><img src={process.env.PUBLIC_URL + "Emojis/LOL.png"} />   
  {this.state.emoji_items.filter(item => item.id == currentId).map(filterID => (
    <number>
      {filterID.lol}
    </number>
  ))}
  </div></a>
  <a href="#" onClick={() => this.updateEmoji("sweet")}>
  <div class="emoji sweet"><img src={process.env.PUBLIC_URL + "Emojis/Sweet.png"} />
  {this.state.emoji_items.filter(item => item.id == currentId).map(filterID => (
    <number>
      {filterID.sweet}
    </number>
  ))}
  </div>
  </a>
  <a href="#" onClick={() => this.updateEmoji("musk")}>
  <div class="emoji musk"><img src={process.env.PUBLIC_URL + "Emojis/Musk.png"} />
  {this.state.emoji_items.filter(item => item.id == currentId).map(filterID => (
    <number>
      {filterID.musk}
    </number>
  ))}
  </div>
  </a>
  <a href="#" onClick={() => this.updateEmoji("donna")}>
  <div class="emoji donna"><img src={process.env.PUBLIC_URL + "Emojis/Donna.png"} />
  {this.state.emoji_items.filter(item => item.id == currentId).map(filterID => (
    <number>
      {filterID.donna}
    </number>
  ))}
  </div>
  </a>
  <a href="#" onClick={() => this.updateEmoji("river")}>
  <div class="emoji river"><img src={process.env.PUBLIC_URL + "Emojis/River.png"} />
  {this.state.emoji_items.filter(item => item.id == currentId).map(filterID => (
    <number>
      {filterID.river}
    </number>
  ))}
  </div>
  </a>
  </div>
  </div>
  <div class={VidBool ? 'videopopup' : 'videopopup hidden'}>
    <video key={this.props.work_video} ref="vidRef" autoPlay loop controls>
      <source src={this.props.work_video} />
    </video>
  </div>
    <Canvas mode="concurrent" frameloop="demand" pixelRatio={window.devicePixelRatio} raycaster={{ filter: this.intersectionsFilter }}
    gl={{ antialias: true }} camera={{ fov: 70, near: 1, far: 10000000, position: [300, -10, 300] }}>
            <ambientLight />
          <React.Suspense fallback={null}>
          <SkyBox />
</React.Suspense>
<ControlsContainer>
</ControlsContainer>
    <React.Suspense fallback={null}>
    <mesh position={[-100 , 0, 0]} scale={[15, 15, 15]}>
    <Clover />
    </mesh>
    </React.Suspense>
    <Suspense fallback={null}>
    <mesh 
         scale={[0.8, 0.8, 0.8]} position={[0, 0, 1]} >
      <M3 />
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
      <mesh onClick={() => this.reply("Jakob Sitter", "Horizon (2021)", "", 1, "", "Online")}><Jakob/></mesh>
      <mesh onClick={() => this.reply("Jakob Sitter", "Horizon (2021)", "", 1, "", "Online")}><Jakob2/></mesh>
      <mesh onClick={() => this.reply("Jakob Sitter", "Horizon (2021)", "", 1, "", "Online")}><Jakob3/></mesh>
      <mesh onClick={() => this.reply("Jakob Sitter", "Horizon (2021)", "", 1, "", "Online")}><Jakob4/></mesh>
      <mesh onClick={() => this.reply("Jakob Sitter", "Horizon (2021)", "", 1, "", "Online")}><Jakob5/></mesh>
    </Suspense>
    <Suspense fallback={null}>
      <mesh position={[0.8, -34, 20]} scale={[20, 20, 20]} onClick={() => this.reply("Conrad Hübbe", "Klasse (2021)", "", 11, "", "Politics")}>
      <Conrad/>
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
      <mesh scale={[15, 15, 15]} position={[10, -80, 70]} onClick={() => this.reply("Christiany Erler", "move the gaze away from the margins to the center (2020)", "This video makes use of theoretical tools used in the analysis of images to scrutinize aspects of social constructs of identity through the comparison of two seemingly different photographs. The reflections in the video are based on studies by Brazilian historian and sociologist, Lourenço Cardoso, as well as on thoughts from also Brazilian philosopher and lawyer, Silvio Luiz de Almeida.", 6, "Chris2.mp4", "Politics")}>
    <Christest />
      </mesh>
</Suspense>
    <Suspense fallback={null}>
      <mesh position={[37, -50, 33.6]} scale={[8, 8, 8]} rotation={[0, THREE.Math.degToRad(-90), 0]} onClick={() => this.reply("Farina Mietchen, Laura Mahnke, Anne Meerpohl", "Ectogenesis (2020/21)", "We work as a collective. Together we approach a utopian scenario in which past and future collide. We use different materials and move together within an approximate visual language. We set a fictional starting point (Daydream) and situate the Exouterus in it. In this way it is possible for us to focus on the queer feminist/ emancipatory potentials, not the deficits, of Ectogenesis.", 3, "Laura.webm", "Chat")}>
      <Laura />
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
      <mesh position={[90, -15, 25]} scale={[5, 5, 5]} rotation={[ THREE.Math.degToRad(0), THREE.Math.degToRad(90), THREE.Math.degToRad(0)]}>
      <mesh rotation={[ THREE.Math.degToRad(30), THREE.Math.degToRad(0), THREE.Math.degToRad(0)]} onClick={() => this.reply("Marthe Fock", "oh no (2021)", "", 10, "", "Chat")}>
      <Marthe4 />
      </mesh>
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
      <mesh position={[0, 20, 10]} scale={[20, 20, 20]} rotation={[THREE.Math.degToRad(-90), THREE.Math.degToRad(0), THREE.Math.degToRad(0)]} onClick={() => this.reply("Zhi-chao Zhu", "49 Days (2021)", "", 14, "", "Biology")}>
      <ChaoVid />
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
      <mesh position={[-130, 350, -30]} scale={[25, 25, 25]} rotation={[THREE.Math.degToRad(0), THREE.Math.degToRad(30), THREE.Math.degToRad(0)]} onClick={() => this.reply("Zhi-chao Zhu", "49 Days (2021)", "", 14, "", "Biology")}>
      <Chao />
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
      <mesh position={[0, -65, -48]} scale={[12, 12, 12]} onClick={() => this.reply("Rahel grote Lambers", "Longing for Belonging (2021)", "", 13, "", "Online")}>
      <Raheltest />
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
      <mesh position={[35.5, -60, 2]} scale={[0.8, 0.8, 0.8]} rotation={[0, THREE.Math.degToRad(90), 0]} onClick={() => this.reply("Karla Zipfel", "strategic growth (2020)", "", 16, "", "Tech")}>
      <Karla />
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
      <mesh position={[0, -90, 80]} scale={[35, 1, 35]} rotation={[0, THREE.Math.degToRad(-90), 0]} onClick={() => this.reply("Gustav Holst Kurtzweil", "No More Excuses for Passivity (2021)", "We find ourselves inside The Engine and get some short glimpses from the outside projected onto the wall of the iron cave when the cylinders are pumping.  We see the life of an assembly line. One of the newest; just arrived from the future. We see the life from the perspective of a dairy cow, and its daily routines within a so-called livestock production apparatus. The gesture is simple, the images are hold together and connected by the timeline. On top of the shifting imagery, we read extracted sentences crafted by Mercedes-Benz/ The Daimler Group copywriting team; seductive nouns, almost impossible to digest without verbs, a language assisting the future of production; innovation saves the earth by creating full-automated, sustainably produced, carbon-neutral SUV’s. It takes eight stomachs. Acceleration or deceleration?", 19, "Gustav.webm", "Online")}>
      <Gustav />
      </mesh>
    </Suspense>   
    <Suspense fallback={null}>
      <mesh position={[0, -10, 10]} scale={[25, 25, 25]} rotation={[0, THREE.Math.degToRad(90), 0]} onClick={() => this.reply("Julia Wolkenhauer", "GAIA (2021)", "This 3d animation is a sound visualization of the Simon Denny class zoom recordings during the winter semester 2020/21. formally inspired by the shape of a certain type of jellyfish, the Siphonophore which are actually colonial organisms. Pantone announced that its 2021 Colors of the Year are PANTONE 13-0647 Illuminating and PANTONE 17-5104 Ultimate Gray, shades chosen for their warmth and dependability.", 4, "", "Tech")}>
      <Julia />
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
      <mesh position={[56, 100, -30.5]} scale={[180, 180, 180]} rotation={[THREE.Math.degToRad(0), THREE.Math.degToRad(90), THREE.Math.degToRad(0)]}>
        <mesh rotation={[THREE.Math.degToRad(65), THREE.Math.degToRad(10), THREE.Math.degToRad(0)]} onClick={() => this.reply("Sam Gora", "Blender (2020)", "", 24, "", "Chat")}>
      <Sam />
      </mesh>
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
      <mesh position={[-34, -50, 1.5]} scale={[10, 10, 10]} rotation={[0, THREE.Math.degToRad(-90), 0]} onClick={() => this.reply("Mathias Riis Andersen", "Rumour (2021)", "The myth of Rumour, the god, used as input for AI generated text.", 21, "Mathias.mp4", "Tech")}>
      <Mathias/>
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
      <mesh position={[-31, -85, 4.8]} scale={[10, 10, 10]} rotation={[0, THREE.Math.degToRad(-90), 0]} onClick={() => this.reply("Amadeus Vogelsang", "We enter the circle at night and are consumed by fire (2021)", "The myth of Rumour, the god, used as input for AI generated text.", 20, "Amadeus.webm", "Tech")}>
      <Amadeus/>
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
      <mesh position={[0, -120, 5]} scale={[22, 22, 22]} rotation={[0, THREE.Math.degToRad(180), 0]} onClick={() => this.reply("Shen Fan", "Intermission: The Times Are Calling (2020)", "", 26, "Shen.webm", "Chat")}>
      <Shen/>
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
      <mesh position={[0, -100, 40]} scale={[22, 22, 22]} rotation={[THREE.Math.degToRad(90), THREE.Math.degToRad(180), 0]} onClick={() => this.reply("Saskia Ackermann", "how (2020/21)", "<a href='https://soundcloud.com/user-499702279/sets/feministische-kollektive-praxis'>how - Gespräche über feministische kollektive Praxis on SoundCloud →</a>", 27, "", "Politics")}>
      <Saskia/>
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
      <mesh position={[35, -115, 35]} scale={[15, 15, 15]} rotation={[0, THREE.Math.degToRad(0), 0]} onClick={() => this.reply("Kim Dall'Armi", "instant distance (negotiating proximities in times of the pandemic) (2020)", "collaborative performance, 45min, @ Kunsthalle Baden-Baden, October 2020 \n \n Deva Schubert, Juan Felipe Amaya Gonzales, Kim Dall'Armi, Luisa Fernanda Alfonso, Kirsche von Bubach, Ina Ritter, Maximilian Wigger", 30, "Kim.mp4", "Chat")}>
      <Kim/>
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
      <mesh position={[0.8, -17, 90]} scale={[8.7, 8.5, 8.5]} onClick={() => this.reply("Taissa Fromme", "Love Language: Rebound/ Relapse (2021)", "", 22, "", "Biology")}>
      <Taissa/>
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
      <mesh position={[0, 6, -55]} scale={[3, 3, 3]} onClick={() => this.reply("Leyla Yenirce", "Propeller (2021)", "", 12, "", "Politics")}>
      <Leyla/>
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
    <mesh  position={[59, 15, 32]} scale={[60, 60, 60]} rotation={[0, 0, THREE.Math.degToRad(40)]} onClick={() => this.reply("Yi Li", "mourning.house (2021)", "Technical support thanks to Danni Zhang and Leyuan Li.", 9, "Yi.webm", "Tech")}>
      <Yi />
      </mesh>
    </Suspense>
    <Suspense fallback={null}>
    <mesh  position={[-57, 15, 18]} scale={[60, 60, 60]} rotation={[THREE.Math.degToRad(0), THREE.Math.degToRad(180), THREE.Math.degToRad(40)]} onClick={() => this.reply("Merlin Reichart", "Place To Be (2019)", "Video documentation from a 2019 room-installation", 2, "Merlin.mp4", "Tech")}>
      <Merlin />
      </mesh>
    </Suspense>
<Suspense fallback={null}>
  <group  rotation={[THREE.Math.degToRad(10), 0, 0]} position={[0, -56, 88]} onClick={() => this.reply("Talya Feldman", "Global White Supremacist Terror: Halle (2020)", "<a href='https://halle.nsu-watch.info/'>halle.nsu-watch.info →</a>", 6, "Talya.mp4", "Politics")}>
      <Ipad scale={[130, 130, 130]} />
      <mesh scale={[12, 17, 10]}  position={[0, 0, -1]}>
      <Talya />
      </mesh>
  </group>
</Suspense>
<Suspense fallback={null}>
<group
         scale={[20, 20, 20]} position={[0.75, -137, 43]} onClick={() => this.reply("Ruxin Liu", "Part-time Jobs (Plants) (2021)", "", 18, "Ruxin.mp4", "Biology")}>
  <Ruxin />
  </group>
</Suspense>
<Suspense fallback={null}>
<mesh
         position={[-27, -30, -55]} scale={[0.8,0.8,0.8]} onClick={() => this.reply("Paul Kolling", "Westbound-190621 series (1–5) (2020)", "", 29, "", "Politics")}>
  <Paul />
  </mesh>
</Suspense>
<Suspense fallback={null}>
<mesh
         position={[17, -34, 60]} scale={[5,5,5]} rotation={[THREE.Math.degToRad(0), THREE.Math.degToRad(200), 0]} onClick={() => this.reply("Lena-Marie Schütte", "What lies within (2021)", "", 28, "", "Politics")}>
  <Lena />
  </mesh>
</Suspense>
<Suspense fallback={null}>
  <group scale={[4.4, 4.4, 4.4]} position={[41.5, -123, 9]} rotation={[0, THREE.Math.degToRad(-90), 0]}>
<mesh
         position={[0, 0, 0]} scale={[2,2,2]} rotation={[0, 0, THREE.Math.degToRad(90)]} onClick={() => this.reply("Nastassja Aurelia Schmidt", "A Take On Attractive Repellents (2021)", "Documentation of a performance where pigeons are confronted with and image, consisting of different grains and coconut oil, depicting various repellents which people use against pigeons.", 8, "Aurel1.webm", "Biology")}>
  <Aurel1 />
  </mesh>
  <mesh 
         position={[5.7, 0, 0]} scale={[2,2,2]} onClick={() => this.reply("Nastassja Aurelia Schmidt", "Casa Born (2021)", "Interview with Martina Born (pigeon friend), who lives with disabled pigeons and takes care of them intensively.", 31, "Aurel2.webm", "Biology")}>
  <Aurel2 />
  </mesh>
  </group>
</Suspense>
<Suspense fallback={null}>
<mesh 
         position={[-10, -127, 75]} rotation={[THREE.Math.degToRad(0), THREE.Math.degToRad(180), THREE.Math.degToRad(0)]} scale={[5.5,5.5,5.5]} onClick={() => this.reply("Insa Wagner", "Chronology Of My Body (2018–2020)", "<a href='https://insa.style/'>insa.style →</a>", 23, "", "Chat")}>
           <MacBook />
  </mesh>
</Suspense>
<Suspense fallback={null}>
<mesh 
         position={[20, -130, 75]} rotation={[THREE.Math.degToRad(0), THREE.Math.degToRad(20), THREE.Math.degToRad(0)]}  scale={[4,4,4]} onClick={() => this.reply("Insa Wagner", "Gender Reveal Party: Analysis (2020)", "", 23, "", "Chat")}>
  <InsaPor />
  </mesh>
</Suspense>
<Suspense fallback={null}>
<mesh 
         position={[10, -130, 60]} scale={[1.5,1.5,1.5]} rotation={[THREE.Math.degToRad(0), THREE.Math.degToRad(20), THREE.Math.degToRad(0)]}  scale={[2,2,2]} onClick={() => this.reply("Insa Wagner", "Gender Reveal Party: Analysis (2020)", "", 23, "", "Chat")}>
  <InsaTextbox />
  </mesh>
</Suspense>
<Suspense fallback={null}>
<mesh 
         position={[-32.3, -110, 60]} scale={[4,4,4]}  onClick={() => this.reply("Heiko Lietz", "Grease monkey carousel II (2021)", "", 5, "", "Chat")}>
  <Heiko />
  </mesh>
</Suspense>
<Suspense fallback={null}>
<mesh 
         position={[0, -50, -85]} rotation={[THREE.Math.degToRad(0), THREE.Math.degToRad(180), 0]} scale={[32,32,32]} onClick={() => this.reply("Leon Roth", "Turbo Kit (2021)", "", 25, "", "Tech")}>
<Leon2 />
  </mesh>
</Suspense>
  </Canvas>
    </div>
  )
}
}
function mapStateToProps(state) {
  return {
    reply_id: state.chat.reply_id,
    reply_name: state.chat.reply_name,
    reply_bool: state.chat.reply_bool,
    work_title: state.chat.work_title,
    work_artist: state.chat.work_artist,
    work_desc: state.chat.work_desc,
    work_video: state.chat.work_video,
    activeChannel: state.channel.activeChannel

  };
}
export default connect(mapStateToProps)(Scene);




