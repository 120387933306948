/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'
import Paul1 from './Works/PaulDisplay1.jpg'
import Paul2 from './Works/PaulDisplay2.jpg'
import Paul3 from './Works/PaulDisplay3.jpg'
import Paul4 from './Works/PaulDisplay4.jpg'
import Paul5 from './Works/PaulDisplay5.jpg'
import * as THREE from 'three'
import { Canvas, useFrame, useUpdate, extend, useLoader, useThree } from 'react-three-fiber'

export default function Paul(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/PaulFrames.gltf')
  const texture1 = useLoader(THREE.TextureLoader, Paul1)
  const texture2 = useLoader(THREE.TextureLoader, Paul2)
  const texture3 = useLoader(THREE.TextureLoader, Paul3)
  const texture4 = useLoader(THREE.TextureLoader, Paul4)
  const texture5 = useLoader(THREE.TextureLoader, Paul5)
  return (
     <group ref={group} {...props} dispose={null} position={[0, 0, 0]}>
      <group position={[6.4, 15, 0.8]}>
      <mesh position={[0, 0, 0]} rotation={[THREE.Math.degToRad(0), THREE.Math.degToRad(180), THREE.Math.degToRad(0)]}>
      <planeBufferGeometry attach="geometry" args={[12.4,17.7]} />
      <meshBasicMaterial  attach="material" map={texture1} toneMapped={false} side={THREE.DoubleSide} />
    </mesh>
    <mesh position={[13.45, 0, 0]} rotation={[THREE.Math.degToRad(0), THREE.Math.degToRad(180), THREE.Math.degToRad(0)]}>
      <planeBufferGeometry attach="geometry" args={[12.4,17.7]} />
      <meshBasicMaterial  attach="material" map={texture2} toneMapped={false} side={THREE.DoubleSide} />
    </mesh>
    <mesh position={[26.9, 0, 0]} rotation={[THREE.Math.degToRad(0), THREE.Math.degToRad(180), THREE.Math.degToRad(0)]}>
      <planeBufferGeometry attach="geometry" args={[12.4,17.7]} />
      <meshBasicMaterial  attach="material" map={texture3} toneMapped={false} side={THREE.DoubleSide} />
    </mesh>
    <mesh position={[40.35, 0, 0]} rotation={[THREE.Math.degToRad(0), THREE.Math.degToRad(180), THREE.Math.degToRad(0)]}>
      <planeBufferGeometry attach="geometry" args={[12.4,17.7]} />
      <meshBasicMaterial  attach="material" map={texture4} toneMapped={false} side={THREE.DoubleSide} />
    </mesh>
    <mesh position={[53.8, 0, 0]} rotation={[THREE.Math.degToRad(0), THREE.Math.degToRad(180), THREE.Math.degToRad(0)]}>
      <planeBufferGeometry attach="geometry" args={[12.4,17.7]} />
      <meshBasicMaterial  attach="material" map={texture5} toneMapped={false} side={THREE.DoubleSide} />
    </mesh>
      </group>
      <group scale={[0.01, 0.01, 0.01]}>
        <mesh material={materials['Paint.003']} geometry={nodes.Object_11.geometry} />
        <mesh material={materials['Paint.003']} geometry={nodes.Object_13.geometry} />
        <mesh material={materials['Paint.003']} geometry={nodes.Object_5.geometry} />
        <mesh material={materials['Paint.003']} geometry={nodes.Object_6.geometry} />
        <mesh material={materials['Paint.003']} geometry={nodes.Object_7.geometry} />
      </group>
    </group>
  )
}

useGLTF.preload('/PaulFrames.gltf')
