import React, { useRef, useState } from 'react'
import Chat3 from "./Chat3";
import Users from "./Users.js";
import { connect } from 'react-redux';
class Channel extends React.Component {
render(){
    return (
      <div class="channel">
        <div class="titlebar"><hash>#</hash>{
                (this.props.activeChannel === 'Chat')
                  ? "gender/style politics" : ""
              }
              {
                (this.props.activeChannel === 'Online')
                  ? "online politics" : ""
              }
              {
                (this.props.activeChannel === 'Biology')
                  ? "biology/anthropocene" : ""
              }
              {
                (this.props.activeChannel === 'Politics')
                  ? "(geo)politics" : ""
              }
              {
                (this.props.activeChannel === 'Tech')
                  ? "tech/space/materiality binaries" : ""
              }</div>
        <div class="inner">
    <React.Fragment></React.Fragment>
        <Chat3 channel={this.props.activeChannel} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    activeChannel: state.channel.activeChannel,
    activeChannel_name: state.channel.activeChannel_name
  };
}
export default connect(mapStateToProps)(Channel);