
const channelReducer = (state={
    activeChannel: "Chat",
    activeChannel_name: 'gender/style politics'
},action)=>{

    switch (action.type){

        case 'SWITCHCHANNEL':
            return {
                activeChannel: action.channel,
                activeChannel_name: action.channel_name,
                items: action.items
            }
        case 'DEFAULT':
            return {
                activeChannel: 'Chat',
                activeChannel_name: 'gender/style politics'
            }

         default:
           return state
    }
    return state;
}

export default channelReducer