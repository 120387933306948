/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function M3(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/Mercedesneu10.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={[0.25, 0.25, 0.25]}>
        <mesh material={materials.paint_black} geometry={nodes.Mercedes_OM502LA004_1.geometry} />
        <mesh material={materials.steel} geometry={nodes.Mercedes_OM502LA004_2.geometry} />
        <mesh material={materials.paint_black_bump} geometry={nodes.Mercedes_OM502LA004_3.geometry} />
        <mesh material={materials.plastic_grey} geometry={nodes.Mercedes_OM502LA004_4.geometry} />
        <mesh material={materials.aluminium} geometry={nodes.Mercedes_OM502LA004_5.geometry} />
        <mesh material={materials.paint_red} geometry={nodes.Mercedes_OM502LA004_6.geometry} />
        <mesh material={materials['Aluminium.001']} geometry={nodes.Mercedes_OM502LA004_7.geometry} />
        <mesh material={materials.plastic_black} geometry={nodes.Mercedes_OM502LA004_8.geometry} />
        <mesh material={materials.rubber_black_bump} geometry={nodes.Mercedes_OM502LA004_9.geometry} />
        <mesh material={materials.aluminium_bump} geometry={nodes.Mercedes_OM502LA004_10.geometry} />
        <mesh material={materials.plastic_greyL} geometry={nodes.Mercedes_OM502LA004_11.geometry} />
      </group>
    </group>
  )
}

useGLTF.preload('/Mercedesneu10.glb')
