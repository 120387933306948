/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Lena(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/Lena.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.02, 2.05, 0]} scale={[1.14, 1.9, 1.13]}>
        <mesh material={materials['Material.002']} geometry={nodes.Cube_1.geometry} />
        <mesh material={materials['Material.005']} geometry={nodes.Cube_2.geometry} />
      </group>
    </group>
  )
}

useGLTF.preload('/Lena.gltf')
