/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef, useState, useEffect } from 'react'
import { useFrame } from 'react-three-fiber'
import { useGLTF } from '@react-three/drei/useGLTF'

import { useAnimations } from '@react-three/drei/useAnimations'

export default function Leon2(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/Leon2.gltf')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    actions.stock.play();
    actions.legsAction.play();
    actions.particles.play();
    actions.turbine.play();
    actions.viruspart.play();
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <group scale={[0.0007, 0.0007, 0.0007]}>
        <group position={[0, -16.02, 29.43]} rotation={[-Math.PI / 2, 0, 0]} scale={[2, 2, 2]}>
          <group scale={[9.2, 9.2, 9.2]}>
            <mesh name="stock" material={materials['Material_5.003']} geometry={nodes.stock.geometry}  />
            <mesh material={materials['Material_6.005']} geometry={nodes.TR_Flag001.geometry} />
            <mesh material={materials['Material_7.002']} geometry={nodes.TR_Flag002.geometry} />
            <mesh material={materials['Material_6.003']} geometry={nodes.TR_Flag003.geometry} />
            <mesh material={materials['Material_7.002']} geometry={nodes.TR_Flag004.geometry} />
            <mesh name="turbine" material={materials['Material_4.003']} geometry={nodes.turbine.geometry} />
            <group position={[-2.16, 0, 0]}>
              <mesh material={materials['Material_0.003']} geometry={nodes.Turbochager001.geometry} />
              <mesh material={materials['Material_1.003']} geometry={nodes.Turbochager002.geometry} />
              <mesh material={materials['Material_2.003']} geometry={nodes.Turbochager003.geometry} />
              <mesh material={materials['Material_3.003']} geometry={nodes.Turbochager004.geometry} />
            </group>
          </group>
        </group>
      </group>
      <group name="viruspart" position={[0, 0, 0.18]} scale={[0.02, 0.02, 0.02]}>
        <mesh name="legsAction" material={materials.legs} geometry={nodes.legsAction.geometry} scale={[10, 10, 10]} />
        <mesh
          name="particles"
          material={materials['Plastic - Glossy (Black)']}
          geometry={nodes.particles.geometry}
          scale={[10, 10, 8.52]}
        />
        <mesh material={materials.body} geometry={nodes.body.geometry} scale={[10, 10, 10]} />
        <mesh material={materials.body} geometry={nodes['body_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.body} geometry={nodes['body_(1)_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.body} geometry={nodes['body_(1)_(1)_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.body} geometry={nodes['body_(1)_(1)_(1)_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.body} geometry={nodes['body_(1)_(1)_(1)_(1)_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.body} geometry={nodes['body_(1)_(1)_(1)_(2)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.body} geometry={nodes['body_(1)_(2)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.body} geometry={nodes['body_(1)_(2)_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.body} geometry={nodes['body_(1)_(2)_(2)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.joint} geometry={nodes.joint.geometry} scale={[10, 10, 10]} />
        <mesh material={materials.joint} geometry={nodes['joint_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.joint} geometry={nodes['joint_(1)_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.joint} geometry={nodes['joint_(1)_(1)_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.joint} geometry={nodes['joint_(1)_(1)_(1)_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.joint} geometry={nodes['joint_(1)_(1)_(1)_(1)_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.joint} geometry={nodes['joint_(1)_(1)_(1)_(2)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.joint} geometry={nodes['joint_(1)_(2)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.joint} geometry={nodes['joint_(1)_(2)_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.joint} geometry={nodes['joint_(1)_(2)_(2)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.legs} geometry={nodes['legs_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.legs} geometry={nodes['legs_(1)_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.legs} geometry={nodes['legs_(1)_(1)_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.legs} geometry={nodes['legs_(1)_(1)_(1)_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.legs} geometry={nodes['legs_(1)_(1)_(1)_(1)_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.legs} geometry={nodes['legs_(1)_(1)_(1)_(2)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.legs} geometry={nodes['legs_(1)_(2)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.legs} geometry={nodes['legs_(1)_(2)_(1)'].geometry} scale={[10, 10, 10]} />
        <mesh material={materials.legs} geometry={nodes['legs_(1)_(2)_(2)'].geometry} scale={[10, 10, 10]} />
        <mesh
          material={materials['Plastic - Glossy (Black)']}
          geometry={nodes['Particles_(1)_(1)'].geometry}
          rotation={[0, 0, 0]}
          scale={[17.52, 17.1, 8.08]}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/Leon2.gltf')
