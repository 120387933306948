/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useLoader } from 'react-three-fiber'
import * as THREE from 'three'

import Rahelimg from './Works/Rahel.jpg'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/rahelnew.gltf')
  const texture = useLoader(THREE.TextureLoader, Rahelimg)

  return (
    <group ref={group} {...props} dispose={null}>
      <group scale={[1, 0.6, 0.07]}>
        <mesh geometry={nodes.Cube_1.geometry} material={materials.Material} />
        <mesh geometry={nodes.Cube_2.geometry} material={materials['Material.001']} />
        <mesh geometry={nodes.Cube_3.geometry} material={materials['Material.002']} />
        <mesh
          geometry={nodes.Cube_4.geometry}
          material={materials['der-einsame-baum-(dorflandschaft-bei-morgenbeleuchtung)-3']}
        />
        <mesh
          geometry={nodes.Cube_5.geometry}
          material={materials['der-einsame-baum-(dorflandschaft-bei-morgenbeleuchtung)-3.001']}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/raheltest.glb')
