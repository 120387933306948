/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/Karla.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh material={materials.low_Bake1_baked} geometry={nodes.karla_zipfel_strategic_growth.geometry} />
    </group>
  )
}

useGLTF.preload('/Karla.glb')
