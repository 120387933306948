/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'
import { useAspect } from "@react-three/drei/useAspect";
import * as THREE from 'three';
export default function MacBook(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/MacBook.glb')
  const [x, y] = useAspect("cover", 1800, 1000);
  const [video] = useState(() => {
    const vid = document.createElement("video");
    vid.src = 'InsaGIF.mp4';
    vid.muted = true;
    vid.loop = true;
    vid.play();
    return vid;
  });
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[0.01, 0.01, 0.01]}>
        <mesh material={materials.keyboard} geometry={nodes.mpm_f21__Apple_MacBook_Pro_15_1.geometry} />
        <mesh material={materials.aluminium} geometry={nodes.mpm_f21__Apple_MacBook_Pro_15_2.geometry} />
        <mesh material={materials.touchpad} geometry={nodes.mpm_f21__Apple_MacBook_Pro_15_3.geometry} />
        <mesh material={materials.metal} geometry={nodes.mpm_f21__Apple_MacBook_Pro_15_4.geometry} />
        <mesh material={materials.white_plastic} geometry={nodes.mpm_f21__Apple_MacBook_Pro_15_5.geometry} />
        <mesh material={materials.rubber} geometry={nodes.mpm_f21__Apple_MacBook_Pro_15_6.geometry} />
        <mesh material={materials.screws} geometry={nodes.mpm_f21__Apple_MacBook_Pro_15_7.geometry} />
        <mesh material={materials.display} geometry={nodes.mpm_f21__Apple_MacBook_Pro_15_8.geometry} />
        <mesh material={materials.apple} geometry={nodes.mpm_f21__Apple_MacBook_Pro_15_9.geometry} />
        <mesh material={materials.display_frame} geometry={nodes.mpm_f21__Apple_MacBook_Pro_15_10.geometry} />
        <mesh material={materials.glass} geometry={nodes.mpm_f21__Apple_MacBook_Pro_15_11.geometry} />
      </group>
      <mesh scale={[0.42, 0.42, 0.42]} position={[-1.35, 0.02, -1.25]} rotation={[0, THREE.Math.degToRad(0), 0]}>
      <planeBufferGeometry args={[5, 7.5]} />
      <meshBasicMaterial>
        <videoTexture attach="map" args={[video]} />
      </meshBasicMaterial>
    </mesh>
    </group>
  )
}

useGLTF.preload('/MacBook.glb')
