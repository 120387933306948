import React, { useRef, useState, useContext } from 'react'
import { connect } from 'react-redux';
import Users from "./Users.js";
import ReactDOM from 'react-dom'
import Chat2 from './Chat2.js'
class Sidebar extends React.Component {
  setActive = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    this.props.dispatch({ type: "SWITCHCHANNEL", channel: e.target.dataset.chat_id, channel_name: e.target.dataset.chat_name });
    e.target.classList.add('active');;
  }
  render(){
    const activeChan = this.props.activeChannel;
      return (
      <div class="sidebar">
        
          <div class="titlebar">the-engine.space</div>
          <ul class="channels">
              <li class="title">▾ Channels</li>
              <li class={
                (activeChan === 'Chat')
                  ? "active"
                  : "nonactive"
              }><a href="#" onClick={this.setActive} data-chat_id="Chat" data-chat_name="gender/style politics"><hash>#</hash>gender/style politics</a></li>
              <li class={
                (activeChan === 'Online')
                  ? "active"
                  : "nonactive"
              }><a href="#" onClick={this.setActive} data-chat_id="Online" data-chat_name="online politics"><hash>#</hash>online politics</a></li>
              <li class={
                (activeChan === 'Biology')
                  ? "active"
                  : "nonactive"
              }><a href="#" onClick={this.setActive} data-chat_id="Biology" data-chat_name="biology/anthropocene"><hash>#</hash>biology/anthropocene</a></li>
              <li class={
                (activeChan === 'Politics')
                  ? "active"
                  : "nonactive"
              }><a href="#" onClick={this.setActive} data-chat_id="Politics" data-chat_name="(geo)politics"><hash>#</hash>(geo)politics</a></li>
              <li class={
                (activeChan === 'Tech')
                  ? "active"
                  : "nonactive"
              }><a href="#" onClick={this.setActive} data-chat_id="Tech" data-chat_name="tech/space/materiality binaries"><hash>#</hash>tech/space/materiality binaries</a></li>
              
          </ul>
          <Users />

      </div>
    );
    }
  }
  function mapStateToProps(state) {
    return {
      activeChannel: state.channel.activeChannel,
      activeChannel_name: state.channel.activeChannel_name
    };
  }
  export default connect(mapStateToProps)(Sidebar);