/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Sam(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/Sam.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.Blender_Sam4.geometry}
        position={[0.37, 0, 0.65]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[4.11, 4.11, 4.11]}
      >
        <meshStandardMaterial
        attach="material"
        color={"#bbb8ff"}
        
        roughness={0.7}
        metalness={0.6}
      />
      </mesh>
    </group>
  )
}

useGLTF.preload('/Sam.glb')
