const chatReducer = (state={
    reply_id: 10,
    reply_name: "",
    reply_bool: false,
    work_title: "",
    work_artist: "",
    work_desc: ""
},action)=>{

    switch (action.type){

    case 'REPLY':
        return {
          reply_bool: action.bool,
          reply_id: action.id,
          reply_name: action.name,
          work_title: action.worktitle,
          work_artist: action.workartist,
          work_desc: action.workdesc,
          work_video: action.workvid
        }
    case 'RESETREPLY':
        return {
          reply_bool: false,
          reply_id: '',
          work_title: "",
          work_artist: "",
          work_desc: ""
        }

         default:
           return state
    }


}

export default chatReducer