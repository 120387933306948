/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Ali(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/Ali.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials.aliarm1}
        geometry={nodes.aliarm1.geometry}
        position={[-0.42, -0.73, -0.07]}
        rotation={[0, 0, 0.12]}
      />
      <mesh
        material={materials.aliarm2}
        geometry={nodes.aliarm2.geometry}
        position={[0.4, -0.71, -0.08]}
        rotation={[0, 0, -0.2]}
      />
      <mesh
        material={materials.alihead}
        geometry={nodes.alihead.geometry}
        position={[-0.01, 0.15, -0.03]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        material={materials.alit}
        geometry={nodes.alit.geometry}
        position={[-0.01, -0.58, -0.1]}
        rotation={[Math.PI / 2, 0, 0]}
      />
    </group>
  )
}

useGLTF.preload('/Ali.glb')
