import { useContext } from 'react';
import React, { Component, useRef, useState, Suspense } from 'react';
import { connect } from 'react-redux';

class TestGlobal extends React.Component {
      
      reply = () => {
        this.props.dispatch({ type: "REPLY", bool: true, id: 20 })
    }

      
  
    render() {
        return (
          <div className="counter">
            <h2>Counter</h2>
            <div>
            <button onClick={this.reply}>REPLY</button>
            </div>
          </div>
        );
      }
  }
  
  function mapStateToProps(state) {
    return {
      count: state.count,
      reply_id: state.reply_id,
      reply_bool: state.reply_bool,
    };
  }
export default connect(mapStateToProps)(TestGlobal);