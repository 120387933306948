/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function EngineModel(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/mercedes copy.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials['Material.002']}
        geometry={nodes.Mercedes_OM502LA002.geometry}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.01, 0.01, 0.01]}
      />
    </group>
  )
}

useGLTF.preload('/mercedes copy.glb')
