import React, { Component, useRef, useState, Suspense } from 'react'
import Scene from "./Scene";
import Channel from "./Channel";
import Sidebar from "./Sidebar";
import Login, { username } from "./Login";
import TestGlobal from './TestGlobal';
import "./styles.css";
import { fetchPosts } from './actions'
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import chatReducer from './chatReducer.js'
import channelReducer from './channelReducer.js'
import emojiReducer from './emojiReducer.js'
import loginReducer from './loginReducer.js'
import thunk from "redux-thunk";

const reducer = combineReducers({
  chat: chatReducer,
  channel: channelReducer,
  emojis: emojiReducer,
  login: loginReducer

})
const store = createStore(reducer)
store.dispatch({ type: "REPLY" });
store.dispatch({ type: "RESETREPLY" });
store.dispatch({ type: "SWITCHCHANNEL" });
store.dispatch({ type: "DEFAULT" });
export const App = () => {
  // Define as many global variables as your app needs, and hooks 
  // to set the state of the variable.
  return (
    <Provider store={store}>
      <Sidebar />
      <Channel />
      <Scene />
      <Login />
      </Provider>
  );
}