import React, { useRef, useState } from 'react'

export default function Users() {
    return (
      <div class="users">
        <ul>
        <li class="title">ARTISTS — 32</li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Talya Feldman</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "/ProfilePics/Jakob.jpg" + ")"}}></div><div class="name">jakob sitter</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "/ProfilePics/Julia.jpg" + ")"}}></div><div class="name">julia wolkenhauer</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Leyla Yenirce</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Christiany Erler</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Gustav Holst Kurtzweil</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "/ProfilePics/Merlin.jpg" + ")"}}></div><div class="name">merlin reichart</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "/ProfilePics/Lena.jpg" + ")"}}></div><div class="name">lena-marie schütte</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Conrad Hübbe</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "/ProfilePics/Rahel.jpg" + ")"}}></div><div class="name">rahel grote lambers</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Merle Dammhayn</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "/ProfilePics/Aurel.jpg" + ")"}}></div><div class="name">Nastassja a. Schmidt</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "/ProfilePics/Yi.jpg" + ")"}}></div><div class="name">Yi Li</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Shen Fan</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Sam Gora</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "/ProfilePics/Marthe.jpg" + ")"}}></div><div class="name">Marthe Fock</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Karla Zipfel</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "/ProfilePics/Amadeus.jpg" + ")"}}></div><div class="name">Amadeus Vogelsang</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Sam Gora</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Mathias r. Andersen</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "/ProfilePics/Taissa.jpg" + ")"}}></div><div class="name">Taissa Fromme</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Leon Roth</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "/ProfilePics/Insa.jpg" + ")"}}></div><div class="name">Insa Wagner</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "/ProfilePics/Paul.jpg" + ")"}}></div><div class="name">Paul Kolling</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Zhi-chao Zhu</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Belia Brückner</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Ruxin Liu</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "/ProfilePics/Kim.jpg" + ")"}}></div><div class="name">Kim Dall'Armi</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">heiko lietz</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Saskia Ackermann</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Farina Mietchen</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Laura Mahnke</div></li>
        <li><div class="pic" style={{backgroundImage: "url(" + "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/discord-512.png" + ")"}}></div><div class="name">Anne Meerpohl</div></li>
        </ul>
      </div>
    )
  }